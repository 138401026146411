import { Environment } from '@abp/ng.core';

const baseUrl = 'https://hour-activity-report-dev.upmuruguay.net';

const oAuthConfig = {
  issuer: 'https://hour-activity-report-backend-dev.upmuruguay.net/',
  redirectUri: baseUrl,
  clientId: 'HourReportEntry_App',
  responseType: 'code',
  scope: 'offline_access HourReportEntry',
  requireHttps: true,
  postLogoutRedirectUri:
    'https://login.microsoftonline.com/9eab37f0-91c6-47e3-9c00-fe8544bd272e/oauth2/v2.0/logout?post_logout_redirect_uri=https://hour-activity-report-dev.upmuruguay.net',
};

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'HourReportEntry-Dev',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://hour-activity-report-backend-dev.upmuruguay.net',
      rootNamespace: 'Upm.HourReportEntry',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  applicationInsights: {
    instrumentationKey: '643eb20a-ea6e-4d9b-835f-118d2305f7c5'
  },
  appVersion: '0.0.1',
} as Environment;
